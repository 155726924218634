import React from "react";
import backgroundImg from "../assets/images/background_single3.png";
import ufoImg from "../assets/images/ufo_full.png";
import smallGearsImg from "../assets/images/smallGears.png";
import chargeSoundFile from "../assets/sounds/ufocharge.mp3";
import crashSoundFile from "../assets/sounds/crash2.mp3";
import winSoundFile from "../assets/sounds/win.mp3";
import looseSoundFile from "../assets/sounds/loose.mp3";
import "../assets/style.css";

function FlightFrenzy() {
  return (
    <div>
      <canvas id="canvas1"></canvas>
      <div className="assets">
        <img id="background" src={backgroundImg} alt="background" />
        <img id="ufo" src={ufoImg} alt="ufo" />
        <img id="smallGears" src={smallGearsImg} alt="small gears" />
        <audio id="charge" src={chargeSoundFile}></audio>
        <audio id="crash" src={crashSoundFile}></audio>
        <audio id="win" src={winSoundFile}></audio>
        <audio id="loose" src={looseSoundFile}></audio>
      </div>
    </div>
  );
}

export default FlightFrenzy;
