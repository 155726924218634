import React from 'react';
import tournamentImg1 from '../assets/images/finished-tournaments-img-1.png';
import tournamentImg2 from '../assets/images/finished-tournaments-img-2.png';
import tournamentImg3 from '../assets/images/finished-tournaments-img-3.png';
import tournamentImg4 from '../assets/images/finished-tournaments-img-4.png';

function FinishedTournaments() {
  return (
    <div>
       <section className="finished-tournaments">
        <div className="overlay pt-120 pb-120">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="con-lg-8">
                        <div className="section-text text-center">
                            <h2 className="title">Finished Tournaments</h2>
                        </div>
                    </div>
                </div>
                <div className="row cus-mar">
                    <div className="col-xl-3 col-lg-4 col-md-6">
                        <div className="single-box">
                            <div className="img-area">
                                <img src={tournamentImg1} alt="image" />
                                <div className="abs-area">
                                    <span className="mdr">Finished</span>
                                </div>
                            </div>
                            <div className="text-area">
                                <h5>Gamzix</h5>
                                <p className="mdr">19.05 - 27.05</p>
                                <div className="prize-pool">
                                    <p className="mdr">Prize pool:</p>
                                    <h5>€10,000</h5>
                                </div>
                                <a href="javascript:void(0)">Result</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6">
                        <div className="single-box">
                            <div className="img-area">
                                <img src={tournamentImg2} alt="image" />
                                <div className="abs-area">
                                    <span className="mdr">Finished</span>
                                </div>
                            </div>
                            <div className="text-area">
                                <h5>Gamzix</h5>
                                <p className="mdr">19.05 - 27.05</p>
                                <div className="prize-pool">
                                    <p className="mdr">Prize pool:</p>
                                    <h5>€10,000</h5>
                                </div>
                                <a href="javascript:void(0)">Result</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6">
                        <div className="single-box">
                            <div className="img-area">
                                <img src={tournamentImg3} alt="image" />
                                <div className="abs-area">
                                    <span className="mdr">Finished</span>
                                </div>
                            </div>
                            <div className="text-area">
                                <h5>Gamzix</h5>
                                <p className="mdr">19.05 - 27.05</p>
                                <div className="prize-pool">
                                    <p className="mdr">Prize pool:</p>
                                    <h5>€10,000</h5>
                                </div>
                                <a href="javascript:void(0)">Result</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6">
                        <div className="single-box">
                            <div className="img-area">
                                <img src={tournamentImg4} alt="image" />
                                <div className="abs-area">
                                    <span className="mdr">Finished</span>
                                </div>
                            </div>
                            <div className="text-area">
                                <h5>Gamzix</h5>
                                <p className="mdr">19.05 - 27.05</p>
                                <div className="prize-pool">
                                    <p className="mdr">Prize pool:</p>
                                    <h5>€10,000</h5>
                                </div>
                                <a href="javascript:void(0)">Result</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  );
}

export default FinishedTournaments;
