import React from 'react';

// Define images
const tableImages = {
  btc: 'assets/images/icon/btc-icon-2.png',
  dash: 'assets/images/icon/dash.png',
  etc: 'assets/images/icon/etc.png',
  trx: 'assets/images/icon/trx.png',
};

// Define table data
const tableData = [
  {
    user: { name: 'Tom Bass', image: 'table-image-1.png' },
    betId: 'b799b8724b',
    betAmount: { currency: 'BTC', value: '0.00000051' },
    multiplier: '3.00X',
    game: 'Dice',
    profit: { currency: 'BTC', value: '0.00000051' },
  },
  // Add more data objects as needed
];

function LatestBets() {
  return (
    <div>
      <section className="latest-bets">
        <div className="overlay pt-120 pb-120">
          <div className="container wow fadeInUp">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="section-header text-center">
                  <h5 className="sub-title">Latest Winners</h5>
                  <h2 className="title">Latest Bets</h2>
                  <p>More and more winners are added every time! To locate the most recent winner's information</p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="d-flex justify-content-center">
                  <ul className="nav" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className="nav-link active" id="all-bets-tab" data-bs-toggle="tab" data-bs-target="#all-bets" type="button" role="tab" aria-controls="all-bets" aria-selected="true">All Bets</button>
                    </li>
                    {/* Add more tabs as needed */}
                  </ul>
                </div>
                <div className="tab-content mt-40">
                  <div className="tab-pane fade show active" id="all-bets" role="tabpanel" aria-labelledby="all-bets-tab">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">User</th>
                            <th scope="col">Bet Id</th>
                            <th scope="col">Bet Amount</th>
                            <th scope="col">Multiplier</th>
                            <th scope="col">Game</th>
                            <th scope="col">Profit</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* Map over table data to generate rows */}
                          {tableData.map((row, index) => (
                            <tr key={index}>
                              <td>
                                <div className="icon-box">
                                  <img src={`assets/images/${row.user.image}`} alt="Image" />
                                  <span>{row.user.name}</span>
                                </div>
                              </td>
                              <td>{row.betId}</td>
                              <td>
                                <div className="icon-box">
                                  <img src={tableImages[row.betAmount.currency]} alt="Image" />
                                  <span>{row.betAmount.value}</span>
                                </div>
                              </td>
                              <td>{row.multiplier}</td>
                              <td className="game">{row.game}</td>
                              <td>
                                <div className="icon-box">
                                  <img src={tableImages[row.profit.currency]} alt="Image" />
                                  <span>{row.profit.value}</span>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default LatestBets;
