import React from 'react';
import aboutUsObj2 from '../assets/images/about-us-obj-2.png';
import aboutUsIllus from '../assets/images/about-us-illus.png';
import playIcon from '../assets/images/icon/play-icon.png';
import aboutUsObj1 from '../assets/images/about-us-obj-1.png';

function AboutUs() {
  return (
    <div>
       <section className="about-us">
        <div className="overlay pt-120">
            <div className="shape-item">
                <img src={aboutUsObj2} alt="image" />
            </div>
            <div className="container">
                <div className="row justify-content-between align-items-center">
                    <div className="col-lg-5 position-relative order-lg-0 order-1">
                        <div className="sec-img position-relative d-rtl">
                            <img src={aboutUsIllus} className="max-un bg-img" alt="image" />
                            <div className="popup-area">
                                <div className="magnific-area position-relative d-flex align-items-center justify-content-around">
                                    <a className="mfp-iframe popupvideo position-absolute" href="https://www.youtube.com/watch?v=Djz8Nc0Qxwk">
                                        <img src={playIcon} alt="icon" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="shape-area">
                            <img src={aboutUsObj1} alt="image" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="lottery-content">
                            <div className="section-header">
                                <h5 className="sub-title">An adventure to explore the galaxy</h5>
                                <h2 className="title">Bringing open-world galaxy to all gamers worldwide</h2>
                                <p>CryptoConquest DuckDsn is a community-based crypto casino that offers their players the best online casino experience possible!At CryptoConquest DuckDsn, we are convinced that blockchain will change the online crypto gambling industry forever! Jump in and check it out for yourself! Have fun and earn crypto!</p>
                            </div>
                            <div className="counter-area">
                                <div className="row cus-mar">
                                    <div className="col-6">
                                        <div className="single-box">
                                            <h3><span className="counter">5000</span>+</h3>
                                            <p>Premium casino games</p>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="single-box">
                                            <h3><span className="counter">24</span>/<span className="counter">7</span></h3>
                                            <p>Industry Best Support</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  );
}

export default AboutUs;
