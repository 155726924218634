import React from 'react'
import Header from '../component/Header'
import Footer from '../component/Footer'
import Banner from '../component/Banner'
import AboutUs from '../component/AboutUs'
import WhyChooseUs from '../component/WhyChooseUs'
import GetStarted from '../component/GetStarted'

function About() {
  return (
    <div>
      <Header />
      <Banner />
      <AboutUs />
      <WhyChooseUs />
      <GetStarted />
      <Footer />
    </div>
  )
}

export default About
