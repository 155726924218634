import React from 'react';
import infoIcon1 from '../assets/images/icon/info-icon-1.png';
import infoIcon2 from '../assets/images/icon/info-icon-2.png';

function GetIntouch() {
  return (
    <div>
      <section className="get-in-touch">
        <div className="overlay pt-120 pb-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="section-text">
                  <h5 className="sub-title">Contact</h5>
                  <h2 className="title">Get in Touch</h2>
                </div>
                <div className="cus-mar d-flex justify-content-between align-items-center">
                  <div className="single-box">
                    <div className="icon-area">
                      <img src={infoIcon1} alt="icon" />
                    </div>
                    <h5>Visit Us</h5>
                    <p>1420 Grant Road, Frankton Queenstown, New Zealand</p>
                  </div>
                  <div className="single-box">
                    <div className="icon-area">
                      <img src={infoIcon2} alt="icon" />
                    </div>
                    <h5>Get in Touch</h5>
                    <p className="phone-email">+123 456 789 0245</p>
                    <p className="phone-email"><a href="/cdn-cgi/l/email-protection" className="__cf_email__" data-cfemail="167e7f56747f6c736477387f79">[email&#160;protected]</a></p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <form action="#">
                  <div className="col-12">
                    <div className="single-input">
                      <label htmlFor="name">Name</label>
                      <input type="text" id="name" placeholder="John Doe" autoComplete="off" />
                    </div>
                    <div className="single-input">
                      <label htmlFor="email">Email</label>
                      <input type="text" id="email" placeholder="your@gmail.com" autoComplete="off" />
                    </div>
                    <div className="single-input">
                      <label htmlFor="message">Message</label>
                      <textarea cols="30" id="message" rows="4" placeholder="Type something here"></textarea>
                    </div>
                    <div className="btn-area text-center">
                      <button className="cmn-btn">Submit</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default GetIntouch;
