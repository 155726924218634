import React from 'react'

function DropWin() {
  return (
    <div>
       <section class="drops-wins">
        <div class="overlay pt-120 pb-120">
            <div class="container wow fadeInUp">
                <div class="row justify-content-between align-items-center">
                    <div class="col-xxl-5 col-xl-6 col-md-6">
                        <div class="section-header">
                            <h5 class="sub-title">Drops & Wins ( Live Casino)</h5>
                            <h2 class="title">Easy way for crypto  Play</h2>
                        </div>
                        <h2 class="currency">€500.000</h2>
                        <div class="countdown d-flex align-items-center">
                            <span>Finishes in:</span>
                            <h6>
                                <span class="days">00</span><span class="ref">Days:</span>
                            </h6>
                            <h6>
                                <span class="hours">00</span><span class="ref">Hrs:</span>
                            </h6>
                            <h6>
                                <span class="minutes">00</span><span class="ref">Mins</span>
                            </h6>
                        </div>
                    </div>
                    <div class="col-xxl-4 col-xl-5 col-md-6">
                        <div class="right-box">
                            <h4 class="mb-30">Deposit and Play</h4>
                            <form action="#">
                                <div class="input-field thumb-select d-flex">
                                    <input type="text" placeholder="0.001" />
                                    <select>
                                        <option value="1">BTC</option>
                                        <option value="2">ETH</option>
                                        <option value="3">LTC</option>
                                    </select>
                                </div>
                                <div class="btn-area mb-30 mt-60">
                                    <a href="javascript:void(0)" class="cmn-btn w-100">
                                        Start Playing
                                        <i class="icon-d-right-arrow-2"></i>
                                    </a>
                                </div>
                                <p>By Clicking you agree with our</p>
                                <div class="link-area gap-1 d-flex align-items-center justify-content-center">
                                    <a href="terms-conditions.html">Terms and Conditions,</a>
                                    <a href="privacy-policy.html">Privacy Policy</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  )
}

export default DropWin
