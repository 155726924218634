import React from 'react';
import counterIcon from '../assets/images/icon/counter-icon-1.png';
import counterIcon2 from '../assets/images/icon/counter-icon-2.png';
import { Link } from 'react-router-dom';

function Banner() {
  return (
    <div>
      <section className="banner-section index">
        <div className="overlay">
          <div className="banner-content">
            <div className="container wow fadeInUp">
              <div className="row justify-content-between align-items-center">
                <div className="col-xl-6 col-lg-6 col-md-8">
                  <div className="main-content">
                    <div className="top-area section-text">
                      <h5 className="sub-title">Welcome To Gaming World</h5>
                      <h1 className="title">
                        Next Level <span>Crypto Gaming</span> Platform
                      </h1>
                      <p className="xlr">Mind-blowing bonuses of 0.05BTC on your first deposit after connecting your wallet with no T & C. Zero withdrawal fees. Flash-like customer</p>
                      <div className="btn-area mt-30">
                        <Link
                          to="/connect"
                          // data-bs-toggle="modal"
                          data-bs-target="#loginMod"
                          className="cmn-btn reg"
                        >
                          Connect Wallet
                          <i className="icon-d-right-arrow-2"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="counter-section">
          <div className="container wow fadeInUp">
            <div className="main-content">
              <div className="row cus-mar">
                <div className="col-lg-6 col-md-6">
                  <div className="single-box d-flex align-items-center">
                    <div className="img-area">
                      <img src={counterIcon} alt="icon" />
                    </div>
                    <div className="text-area">
                      <h6>Weekly Draw Prizes</h6>
                      <h3>
                        $<span className="counter">1350</span>
                      </h3>
                      <p>
                        Next Draw in <span>04d 21h 46m</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="single-box second d-flex align-items-center">
                    <div className="img-area">
                      <img src={counterIcon2} alt="icon" />
                    </div>
                    <div className="text-area">
                      <h6>Paid Overall</h6>
                      <h3>
                        $<span className="counter">1144624</span>
                      </h3>
                      <p>
                        Overall Winnings <span>paid to players</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Banner;
