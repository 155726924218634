import React from "react";
import jackpotIcon from "../assets/images/jackpot-icon.png";
import jackpotImage1 from "../assets/images/jackpot-image-1.png";
import jackpotImage2 from "../assets/images/jackpot-image-2.png";
import jackpotImage3 from "../assets/images/jackpot-image-3.png";
import jackpotImage4 from "../assets/images/jackpot-image-4.png";
import { Link } from "react-router-dom";

function TotalJackpot() {
  return (
    <div>
      <section className="total-jackpot">
        <div className="overlay pt-120 pb-120">
          <div className="container wow fadeInUp">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="section-area text-center">
                  <h5 className="sub-title">Latest Winners</h5>
                  <h3 className="title">122 163 738,00 EUR</h3>
                  <div className="btn-area mt-40">
                    <Link to="/games" className="cmn-btn">
                      All Jackpot Games
                      <i className="icon-d-right-arrow-2"></i>
                    </Link>
                  </div>
                  <div className="icon-img">
                    <img src={jackpotIcon} alt="Jackpot icon" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="title mb-20 d-flex justify-content-between">
                  <h4>Jackpot Games</h4>
                  <a href="javascript:void(0)">Show All</a>
                </div>
                <div className="jackpot-carousel">
                  <div className="single-slide">
                    <div className="box">
                      <div className="img-box">
                        <img src={jackpotImage1} alt="Jackpot game" />
                      </div>
                      <div className="img-box">
                        <img src={jackpotImage2} alt="Jackpot game" />
                      </div>
                    </div>
                  </div>
                  <div className="single-slide">
                    <div className="box">
                      <div className="img-box">
                        <img src={jackpotImage3} alt="Jackpot game" />
                      </div>
                      <div className="img-box">
                        <img src={jackpotImage1} alt="Jackpot game" />
                      </div>
                    </div>
                  </div>
                  <div className="single-slide">
                    <div className="box">
                      <div className="img-box">
                        <img src={jackpotImage4} alt="Jackpot game" />
                      </div>
                      <div className="img-box">
                        <img src={jackpotImage3} alt="Jackpot game" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default TotalJackpot;
