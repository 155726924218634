import React from 'react'
import Header from '../component/Header'
import LogReg from '../component/LogReg'
import BannerTerms from '../component/BannerTerms'
import TermsAndCodition from '../component/TermsAndCodition'
import Footer from '../component/Footer'

function TermsCondition() {
  return (
    <div>
      <Header />
      <LogReg />
      <BannerTerms />
      <TermsAndCodition />
      <Footer />
    </div>
  )
}

export default TermsCondition
