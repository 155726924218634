import React from "react";
import popularGameItem from "../assets/images/popular-game-item.png";
import popularGameImage1 from "../assets/images/popular-game-image-1.png";
import popularGameImage2 from "../assets/images/popular-game-image-2.png";
import popularGameImage3 from "../assets/images/popular-game-image-3.png";
import popularGameImage4 from "../assets/images/popular-game-image-4.png";
import popularGameImage5 from "../assets/images/popular-game-image-5.png";
import popularGameImage6 from "../assets/images/popular-game-image-6.png";
import { Link } from "react-router-dom";

function PopularGame() {
  return (
    <div>
      <section className="popular-game">
        <div className="overlay pt-120 pb-120">
          <div className="abs-item">
            <img src={popularGameItem} alt="icon" />
          </div>
          <div className="container">
            <div className="row justify-content-center wow fadeInUp">
              <div className="col-lg-6 text-center">
                <div className="section-text">
                  <h5 className="sub-title">Easy way for crypto Play</h5>
                  <h2 className="title">Popular Game</h2>
                  <p>
                    Unparalleled slots to give lovers of the gambling world an
                    exciting gaming experience par excellence
                  </p>
                </div>
              </div>
            </div>
            <div className="row wow fadeInUp">
              <div className="games-carousel">
                <div className="single">
                  <div className="single-box">
                    <img src={popularGameImage1} alt="Image" />
                  </div>
                </div>
                <div className="single">
                  <div className="single-box">
                    <img src={popularGameImage2} alt="Image" />
                  </div>
                </div>
                <div className="single">
                  <div className="single-box">
                    <img src={popularGameImage3} alt="Image" />
                  </div>
                </div>
                <div className="single">
                  <div className="single-box">
                    <img src={popularGameImage4} alt="Image" />
                  </div>
                </div>
                <div className="single">
                  <div className="single-box">
                    <img src={popularGameImage5} alt="Image" />
                  </div>
                </div>
                <div className="single">
                  <div className="single-box">
                    <img src={popularGameImage6} alt="Image" />
                  </div>
                </div>
                <div className="single">
                  <div className="single-box">
                    <img src={popularGameImage3} alt="Image" />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="btn-area mt-40 text-center">
                  <Link to="/games" className="cmn-btn">
                    All Games
                    <i className="icon-d-right-arrow-2"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PopularGame;
