import React from 'react';
import featuresIcon1 from '../assets/images/icon/features-icon-1.png';
import featuresIcon2 from '../assets/images/icon/features-icon-2.png';
import featuresIcon3 from '../assets/images/icon/features-icon-3.png';
import featuresIcon4 from '../assets/images/icon/features-icon-4.png';
import { Link } from 'react-router-dom';

function Features() {
  return (
    <div>
      <section className="features">
        <div className="overlay pt-120 pb-120">
          <div className="container wow fadeInUp">
            <div className="row align-items-center">
              <div className="col-lg-9">
                <div className="section-top">
                  <div className="section-text">
                    <h5 className="sub-title">The Fastest Withdrawals In The Galaxy</h5>
                    <h2 className="title">Enjoy fast, secure withdrawals at Casino CryptoConquest DuckDsn.</h2>
                    <p>
                      Find gamers who care about the same things you do — whether you have shared values or common goals
                      — and connect with them in a meaningful way.
                    </p>
                  </div>
                  <div className="btn-area">
                    <Link to="/games" className="cmn-btn">
                      Enter our Universe
                      <i className="icon-d-right-arrow-2"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row cus-mar pt-120">
              <div className="col-xl-3 col-sm-6">
                <div className="single-box">
                  <div className="icon-box">
                    <img src={featuresIcon1} alt="Image" />
                  </div>
                  <h3>100</h3>
                  <p>Welcome Bonus</p>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6">
                <div className="single-box second">
                  <div className="icon-box">
                    <img src={featuresIcon2} alt="Image" />
                  </div>
                  <h3>10%</h3>
                  <p>Cash Back </p>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6">
                <div className="single-box third">
                  <div className="icon-box">
                    <img src={featuresIcon3} alt="Image" />
                  </div>
                  <h3>5000%</h3>
                  <p>Games</p>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6">
                <div className="single-box fourth">
                  <div className="icon-box">
                    <img src={featuresIcon4} alt="Image" />
                  </div>
                  <h3>100%</h3>
                  <p>Secure & Stable</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Features;
