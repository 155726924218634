import React from "react";
import searchIcon from "../assets/images/icon/search-icon-2.png";
import tournamentImg1 from "../assets/images/tournament-img-1.png";
import tournamentImg2 from "../assets/images/tournament-img-2.png";
import tournamentImg3 from "../assets/images/tournament-img-3.png";
import tournamentImg4 from "../assets/images/tournament-img-4.png";
import tournamentIllus from "../assets/images/tournament-illus.png";
import { Link } from "react-router-dom";

function TournamentsGames() {
  return (
    <div>
      <section className="tournaments">
        <div className="overlay pt-120 pb-120">
          <div className="container cus-mar">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="lottery-content">
                  <div className="section-text text-center">
                    <h5 className="sub-title">Join Your Best Tournament</h5>
                    <h2 className="title">Tournaments Games</h2>
                    <p>
                      Play in multi-player tournaments or head to head for cash
                      prizes
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 nav-head mb-40 gap-4 d-flex align-items-center justify-content-between">
                <div className="input-field">
                  <select>
                    <option value="1">Upcoming</option>
                    <option value="2">Upcoming 1</option>
                    <option value="3">Upcoming 2</option>
                    <option value="4">Upcoming 3</option>
                  </select>
                </div>
                <div className="input-field">
                  <select>
                    <option value="1">Select Team Size</option>
                    <option value="2">1</option>
                    <option value="3">2</option>
                    <option value="4">3</option>
                  </select>
                </div>
                <div className="input-field">
                  <select>
                    <option value="1">Select Entry Fee</option>
                    <option value="2">1</option>
                    <option value="3">2</option>
                    <option value="4">3</option>
                  </select>
                </div>
                <div className="input-field">
                  <div className="input-area d-flex align-items-center">
                    <img src={searchIcon} alt="icon" />
                    <input type="text" placeholder="Search" />
                  </div>
                </div>
              </div>
            </div>
            <div className="single-box">
              <div className="row">
                <div className="col-lg-8">
                  <img src={tournamentImg1} alt="image" />
                </div>
                <div className="col-lg-4">
                  <div className="single-content">
                    <h5>Weekend Runs</h5>
                    <div className="count-area mt-4 mb-4 text-center">
                      <h6>Ends in:</h6>
                      <div className="countdown mt-2 d-flex align-items-center justify-content-center">
                        <h6>
                          <span className="days">00</span>
                          <span className="ref">D :</span>
                        </h6>
                        <h6>
                          <span className="hours">00</span>
                          <span className="ref">H :</span>
                        </h6>
                        <h6>
                          <span className="minutes">00</span>
                          <span className="ref">M :</span>
                        </h6>
                        <h6>
                          <span className="seconds">00</span>
                          <span className="ref">S</span>
                        </h6>
                      </div>
                    </div>
                    <div className="prize-pool">
                      <p className="mdr">Prize pool:</p>
                      <h5>€10,000</h5>
                    </div>
                    <div className="btn-area mt-4">
                      <Link to="/connect" className="cmn-btn">
                        Participate
                      </Link>
                    </div>
                    <div className="abs-area">
                      <img src={tournamentIllus} alt="image" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="single-box">
              <div className="row">
                <div className="col-lg-8">
                  <img src={tournamentImg2} alt="image" />
                </div>
                <div className="col-lg-4">
                  <div className="single-content">
                    <h5>Late Spring Break</h5>
                    <div className="count-area mt-4 mb-4 text-center">
                      <h6>Ends in:</h6>
                      <div className="countdown mt-2 d-flex align-items-center justify-content-center">
                        <h6>
                          <span className="days">00</span>
                          <span className="ref">D :</span>
                        </h6>
                        <h6>
                          <span className="hours">00</span>
                          <span className="ref">H :</span>
                        </h6>
                        <h6>
                          <span className="minutes">00</span>
                          <span className="ref">M :</span>
                        </h6>
                        <h6>
                          <span className="seconds">00</span>
                          <span className="ref">S</span>
                        </h6>
                      </div>
                    </div>
                    <div className="prize-pool">
                      <p className="mdr">Prize pool:</p>
                      <h5>€10,000</h5>
                    </div>
                    <div className="btn-area mt-4">
                      <Link to="/connect" className="cmn-btn">
                        Participate
                      </Link>
                    </div>
                    <div className="abs-area">
                      <img src={tournamentIllus} alt="image" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="single-box">
              <div className="row">
                <div className="col-lg-8">
                  <img src={tournamentImg3} alt="image" />
                </div>
                <div className="col-lg-4">
                  <div className="single-content">
                    <h5>The Wild Week</h5>
                    <div className="count-area mt-4 mb-4 text-center">
                      <h6>Ends in:</h6>
                      <div className="countdown mt-2 d-flex align-items-center justify-content-center">
                        <h6>
                          <span className="days">00</span>
                          <span className="ref">D :</span>
                        </h6>
                        <h6>
                          <span className="hours">00</span>
                          <span className="ref">H :</span>
                        </h6>
                        <h6>
                          <span className="minutes">00</span>
                          <span className="ref">M :</span>
                        </h6>
                        <h6>
                          <span className="seconds">00</span>
                          <span className="ref">S</span>
                        </h6>
                      </div>
                    </div>
                    <div className="prize-pool">
                      <p className="mdr">Prize pool:</p>
                      <h5>€10,000</h5>
                    </div>
                    <div className="btn-area mt-4">
                      <Link to="/connect" className="cmn-btn">
                        Participate
                      </Link>
                    </div>
                    <div className="abs-area">
                      <img src={tournamentIllus} alt="image" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="single-box">
              <div className="row">
                <div className="col-lg-8">
                  <img src={tournamentImg4} alt="image" />
                </div>
                <div className="col-lg-4">
                  <div className="single-content">
                    <h5>Slot of the Week</h5>
                    <div className="count-area mt-4 mb-4 text-center">
                      <h6>Ends in:</h6>
                      <div className="countdown mt-2 d-flex align-items-center justify-content-center">
                        <h6>
                          <span className="days">00</span>
                          <span className="ref">D :</span>
                        </h6>
                        <h6>
                          <span className="hours">00</span>
                          <span className="ref">H :</span>
                        </h6>
                        <h6>
                          <span className="minutes">00</span>
                          <span className="ref">M :</span>
                        </h6>
                        <h6>
                          <span className="seconds">00</span>
                          <span className="ref">S</span>
                        </h6>
                      </div>
                    </div>
                    <div className="prize-pool">
                      <p className="mdr">Prize pool:</p>
                      <h5>€10,000</h5>
                    </div>
                    <div className="btn-area mt-4">
                      <Link to="/connect" className="cmn-btn">
                        Participate
                      </Link>
                    </div>
                    <div className="abs-area">
                      <img src={tournamentIllus} alt="image" />
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default TournamentsGames
