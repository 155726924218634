import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './pages/Home';
import Lottery from "./pages/Lottery";
import About from "./pages/About";
import Tournaments from "./pages/Tournaments";
import TermsCondition from "./pages/TermsCondition";
import Contact from "./pages/Contact";
import Connect from "./pages/Connect";
import Wallet from "./pages/Wallet";
import FlightFrenzy from "./pages/FlightFrenzy";


function App() {
  return (
    <div className="App">
        <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/games" element={<Tournaments />} />
          <Route exact path="/lottery" element={<Lottery />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/terms-and-condition" element={<TermsCondition />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/connect" element={<Connect />} />
          <Route exact path="/wallet" element={<Wallet />} />
          <Route exact path="/flight-frenzy" element={<FlightFrenzy />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
