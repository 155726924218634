import React from 'react';
import whyChooseIcon1 from '../assets/images/icon/why-choose-icon-1.png';
import whyChooseIcon2 from '../assets/images/icon/why-choose-icon-2.png';
import whyChooseIcon3 from '../assets/images/icon/why-choose-icon-3.png';
import whyChooseIcon4 from '../assets/images/icon/why-choose-icon-4.png';
import whyChooseIcon5 from '../assets/images/icon/why-choose-icon-5.png';
import whyChooseIcon6 from '../assets/images/icon/why-choose-icon-6.png';

function WhyChooseUs() {
  return (
    <div>
       <section className="why-choose">
        <div className="overlay pt-120 pb-120">
            <div className="container">
                <div className="row justify-content-between align-items-center">
                    <div className="col-lg-12">
                        <div className="section-header text-center">
                            <h5 className="sub-title">Why choose it</h5>
                            <h2 className="title">We value all our players and we understand the thrill of online gaming</h2>
                            <p>Mind-blowing bonuses. Zero withdrawal fees. Flash-like customer support.</p>
                        </div>
                    </div>
                </div>
                <div className="row cus-mar">
                    <div className="col-lg-4 col-md-6">
                        <div className="single-box">
                            <div className="icon-area">
                                <img src={whyChooseIcon1} alt="image" />
                            </div>
                            <h5>VIP Membership</h5>
                            <p>Become a Premium VIP Member for a month and enjoy the benefits that will enrich your gambling adventures.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="single-box">
                            <div className="icon-area">
                                <img src={whyChooseIcon2} alt="image" />
                            </div>
                            <h5>Provably fair</h5>
                            <p>We utilize the industry standard for provably fair gaming. Verify drawings with our or 3rd party verification tools.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="single-box">
                            <div className="icon-area">
                                <img src={whyChooseIcon3} alt="image" />
                            </div>
                            <h5>Fast Withdrawals</h5>
                            <p>Get your winnings paid out to your wallet on your own terms. Simply select the withdrawal speed and confirm.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="single-box">
                            <div className="icon-area">
                                <img src={whyChooseIcon4} alt="image" />
                            </div>
                            <h5>Progressive jackpots</h5>
                            <p>With every bet on dice and roulette you have the chance to win our ever growing jackpot.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="single-box">
                            <div className="icon-area">
                                <img src={whyChooseIcon5} alt="image" />
                            </div>
                            <h5>Low house edge</h5>
                            <p>You’re here to win often and a lot. Our games have extremely low house edge, starting at only 1%.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="single-box">
                            <div className="icon-area">
                                <img src={whyChooseIcon6} alt="image" />
                            </div>
                            <h5>Secure and private</h5>
                            <p>We don’t collect sensitive private information such as bank accounts, which makes your stay with us safe and private.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  );
}

export default WhyChooseUs;
