import React from "react";
import favImage from "../assets/images/fav.png";
import logoImage from "../assets/images/logo.png";
import { Link } from "react-router-dom";
import "../assets/css/header.css";

function Header() {
  return (
    <div>
      <header className="header-section">
        <div className="overlay">
          <div className="container">
            <div className="row d-flex header-area">
              <nav className="navbar navbar-expand-lg navbar-light">
                <a className="navbar-brand" href="index.html">
                  <img
                    src={favImage}
                    className="fav d-none d-lg-block d-xl-none"
                    alt="fav"
                  />
                  <img
                    src={logoImage}
                    className="logo d-block d-lg-none d-xl-block"
                    alt="logo"
                  />
                </a>
                <button
                  className="navbar-toggler collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbar-content"
                >
                  <i className="fas fa-bars"></i>
                </button>
                <div
                  className="collapse navbar-collapse justify-content-between"
                  id="navbar-content"
                >
                  <ul className="navbar-nav mr-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                      <Link className="nav-link active" to="/">
                        Home
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/games">
                        Games
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/lottery">
                        Lottery
                      </Link>
                    </li>
                    <li className="nav-item dropdown main-navbar">
                      <a
                        className="nav-link dropdown-toggle"
                        href="javascript:void(0)"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                      >
                        About Us
                      </a>
                      <ul className="dropdown-menu main-menu shadow">
                        <li>
                          <Link className="nav-link" to="/about">
                            About Us
                          </Link>
                        </li>
                        <li>
                          <Link className="nav-link" to="terms-and-condition">
                            Terms Conditions
                          </Link>
                        </li>
                        {/* <li><a className="nav-link" href="privacy-policy.html">Privacy Policy</a></li> */}
                      </ul>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/contact">
                        Contact
                      </Link>
                    </li>
                  </ul>
                  <div className="right-area header-action d-flex align-items-center max-un">
                    {/* BTC Balance Bar */}
                    <span className="btc-balance-bar mr-3">0.05 BTC</span>
                    <Link to="/connect">
                    {/* <Link to="/flight-frenzy"> */}
                      <button
                        type="button"
                        className="cmn-btn reg"
                        data-bs-target="#loginMod"
                      >
                        Connect Wallet
                      </button>
                    </Link>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
