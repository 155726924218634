import React from 'react'
import Header from '../component/Header'
import Footer from '../component/Footer'
import LogReg from '../component/LogReg'
import BannerTournaments from '../component/BannerTournaments'
import FinishedTournaments from '../component/FinishedTournaments'
import PlayersOfTheWeek from '../component/PlayersOfTheWeek'
import TournamentsGames from '../component/TournamentsGames'


function Tournaments() {
  return (
    <div>
      <Header />
      <LogReg />
      <BannerTournaments />
      <TournamentsGames />
      <FinishedTournaments />
      <PlayersOfTheWeek />
      <Footer />
    </div>
  )
}

export default Tournaments
