import React from "react";
import emailIcon from "../assets/images/icon/email-icon.png";
import securityIcon from "../assets/images/icon/security.png";
import getStartedIllus from "../assets/images/get-start-illus.png";

function GetStarted() {
  return (
    <div>
      <section className="get-start pb-120">
        <div className="overlay pt-120">
          <div className="container wow fadeInUp">
            <div className="row justify-content-between align-items-end">
              <div className="col-xl-5 col-lg-6">
                <div className="star-form">
                  <form action="#">
                    <div className="section-header text-center">
                      <h5 className="sub-title">Ready to get started?</h5>
                      <h3 className="title">Welcome Bonus €1,500 + 270 FS</h3>
                      <p>
                        More and more winners are added every time! To locate
                        the most recent winner's information
                      </p>
                    </div>
                    <div className="input-area d-flex align-items-center">
                      <img src={emailIcon} alt="icon" />
                      <input type="text" placeholder="Email" />
                    </div>
                    <div className="input-area d-flex align-items-center">
                      <img src={securityIcon} alt="icon" />
                      <input type="text" placeholder="Password" />
                    </div>
                    <div className="sidebar-single-item">
                      <label className="checkbox-single d-flex">
                        <span className="content-area">
                          <span className="checkbox-area d-flex">
                            <input type="checkbox" checked="checked" />
                            <span className="checkmark"></span>
                          </span>
                          <span className="item-title d-flex align-items-center">
                            <span>
                              I am at least 18 years of age and I have read,
                              accepted and agreed to the{" "}
                              <u>
                                <a href="javascript:void(0)">Privacy Policy</a>,
                                Bonus T&C,{" "}
                                <a href="javascript:void(0)">
                                  Terms and Conditions
                                </a>
                              </u>
                            </span>
                          </span>
                        </span>
                      </label>
                      <label className="checkbox-single d-flex align-items-center">
                        <span className="content-area">
                          <span className="checkbox-area d-flex">
                            <input type="checkbox" />
                            <span className="checkmark"></span>
                          </span>
                          <span className="item-title d-flex align-items-center">
                            <span>I want to receive promotions by e-mail.</span>
                          </span>
                        </span>
                      </label>
                      <div className="btn-area">
                        <a
                          href="javascript:void(0)"
                          className="cmn-btn reg mt-4"
                          data-bs-toggle="modal"
                          data-bs-target="#loginMod"
                        >
                          Get Start Now
                          <i className="icon-d-right-arrow-2"></i>
                        </a>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-xl-7 col-lg-5 text-center">
                <img src={getStartedIllus} alt="Image" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default GetStarted;
