import React from 'react';
import howWorksIcon1 from '../assets/images/icon/how-works-icon-1.png';
import howWorksIcon2 from '../assets/images/icon/how-works-icon-2.png';
import howWorksIcon3 from '../assets/images/icon/how-works-icon-3.png';

function HowItWorks() {
  return (
    <div>
      <section className="how-works">
        <div className="overlay pb-120">
          <div className="container wow fadeInUp">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="section-header text-center">
                  <h5 className="sub-title">How it works</h5>
                  <h2 className="title">It’s Really Easy!</h2>
                  <p>It’s easier than you think. Follow 3 simple easy steps</p>
                </div>
              </div>
            </div>
            <div className="row cus-mar">
              <div className="col-xl-4 col-sm-6">
                <div className="single-box text-center">
                  <div className="thumb">
                    <img src={howWorksIcon1} alt="icon" />
                  </div>
                  <div className="text-area">
                    <h5>Instant Register</h5>
                    <p>Instant Register within 5 minutes</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-sm-6">
                <div className="single-box mid text-center">
                  <div className="thumb">
                    <img src={howWorksIcon2} alt="icon" />
                  </div>
                  <div className="text-area">
                    <h5>Fastest Deposit</h5>
                    <p>Deposit with Fastest Lightning Network</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-sm-6">
                <div className="single-box text-center">
                  <div className="thumb">
                    <img src={howWorksIcon3} alt="icon" />
                  </div>
                  <div className="text-area">
                    <h5>Play and get Win</h5>
                    <p>Get fastest Withdraw with Lightning Network</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HowItWorks;
