import React from 'react';
import playersCircleImg from '../assets/images/players-circle.png';
import playersImg1 from '../assets/images/players-img-1.png';
import playersImg2 from '../assets/images/players-img-2.png';
import playersImg3 from '../assets/images/players-img-3.png';

function PlayersOfTheWeek() {
  return (
    <div>
      <section className="players-of-week">
        <div className="overlay pb-120">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="con-lg-8">
                        <div className="section-header text-center">
                            <h2 className="title">Players of The Week</h2>
                            <p>We take a look at the best player of the week awarded </p>
                        </div>
                    </div>
                </div>
                <div className="row cus-mar">
                    <div className="col-xl-4 col-md-6">
                        <div className="single-box">
                            <div className="abs-area d-flex align-items-center justify-content-center">
                                <span className="xlr">1</span>
                            </div>
                            <div className="img-area d-flex align-items-center justify-content-center">
                                <img src={playersCircleImg} className="img-bg" alt="image" />
                                <img src={playersImg1} className="img-1" alt="image" />
                            </div>
                            <div className="text-area mt-4">
                                <h5>Darin Allison</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                        <div className="single-box">
                            <div className="abs-area d-flex align-items-center justify-content-center">
                                <span className="xlr">2</span>
                            </div>
                            <div className="img-area d-flex align-items-center justify-content-center">
                                <img src={playersCircleImg} className="img-bg" alt="image" />
                                <img src={playersImg2} className="img-1" alt="image" />
                            </div>
                            <div className="text-area mt-4">
                                <h5>Don Gomez</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                        <div className="single-box">
                            <div className="abs-area d-flex align-items-center justify-content-center">
                                <span className="xlr">3</span>
                            </div>
                            <div className="img-area d-flex align-items-center justify-content-center">
                                <img src={playersCircleImg} className="img-bg" alt="image" />
                                <img src={playersImg3} className="img-1" alt="image" />
                            </div>
                            <div className="text-area mt-4">
                                <h5>Ron Nichols</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  );
}

export default PlayersOfTheWeek;
