import React from 'react'
import Preloader from '../component/Preloader'
import Header from '../component/Header'
import LogReg from '../component/LogReg'
import Banner from '../component/Banner'
import PopularGame from '../component/PopularGame'
import Features from '../component/Features'
import HowItWorks from '../component/HowItWorks'
import DropWin from '../component/DropWin'
import LatestBets from '../component/LatestBets'
import TotalJackpot from '../component/TotalJackpot'
import ReferSection from '../component/ReferSection'
import Footer from '../component/Footer'

function Home() {
  return (
    <div>
      {/* <Preloader /> */}
      <Header />
      <LogReg />
      <Banner />
      <PopularGame />
      <Features />
      <HowItWorks />
      <DropWin />
      {/* <LatestBets /> */}
      <TotalJackpot />
      <ReferSection />
      <Footer />
    </div>
  )
}

export default Home
