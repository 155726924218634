import React from "react";

function TermsAndCodition() {
  return (
    <div>
      <section class="privacy-content terms">
        <div class="overlay pt-120 pb-120">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-10">
                <div class="top-wrapper">
                  <h4>Terms and Conditions</h4>
                  <p>
                    Welcome to CryptoConquest DuckDns! These Terms and
                    Conditions govern your use of our website and services. By
                    accessing or using our website, you agree to comply with
                    these Terms and Conditions. Please read them carefully.
                  </p>

                  <div class="safe-data">
                    <h5>
                      Your data is safe with us, we will not share any
                      information with external sources.
                    </h5>
                    <p>
                      At CryptoConquest, we are committed to protecting
                      your privacy. We will not share any information with
                      external sources. Your data is safe with us. We collect
                      and process personal information in accordance with our
                      Privacy Policy. By using our website and services, you
                      consent to the collection and use of your information as
                      described in our Privacy Policy. For more information
                      about how we collect, use, and protect your personal
                      information, please read our Privacy Policy.
                    </p>
                  </div>
                  <div class="safe-data">
                    <h5>Acceptance of Terms</h5>
                    <p>
                      By accessing or using our website, you agree to be bound
                      by these Terms and Conditions and all applicable laws and
                      regulations. If you do not agree with any part of these
                      terms, you may not access the website or use our services.
                    </p>
                  </div>
                  <div class="safe-data">
                    <h5>User Conduct</h5>
                    <p>
                      You agree to use our website and services only for lawful
                      purposes and in accordance with these Terms and
                      Conditions. You must not engage in any activity that
                      disrupts or interferes with the operation of the website
                      or its services.
                    </p>
                  </div>
                  <div class="safe-data">
                    <h5> Intellectual Property</h5>
                    <p>
                      All content and materials available on our website,
                      including but not limited to text, graphics, logos,
                      images, and software, are the property of [Your Crypto
                      Game Site] or its licensors and are protected by
                      copyright, trademark, and other intellectual property
                      laws.
                    </p>
                  </div>
                  <div class="safe-data">
                    <h5> Changes to Terms and Conditions</h5>
                    <p>
                      We reserve the right to modify or revise these Terms and
                      Conditions at any time, without prior notice. Your
                      continued use of our website and services following the
                      posting of any changes constitutes acceptance of those
                      changes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default TermsAndCodition;
