import React from 'react'
import Header from '../component/Header'
import Footer from '../component/Footer'
import LogReg from '../component/LogReg'
import BannerContact from '../component/BannerContact'
import GetIntouch from '../component/GetIntouch'
import Faq from '../component/Faq'
import SocialMedia from '../component/SocialMedia'

function Contact() {
  return (
    <div>
      <Header />
      <LogReg />
      <BannerContact />
      <GetIntouch />
      <Faq />
      <SocialMedia />
      <Footer />
    </div>
  )
}

export default Contact
