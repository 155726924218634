import React from 'react';
import referShape from '../assets/images/refer-shape.png';
import referIllus from '../assets/images/refer-illus.png';

function ReferSection() {
  return (
    <div>
      <section className="refer-section">
        <div className="overlay pt-120 pb-120">
          <div className="container wow fadeInUp">
            <div className="shape-area">
              <img src={referShape} alt="Refer shape" />
            </div>
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 order-lg-0 order-1">
                <div className="bg-img d-rtl">
                  <img src={referIllus} alt="Refer illustration" className="max-un" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="section-text">
                  <h5 className="sub-title">Earn more with</h5>
                  <h2 className="title">Refer and Earn</h2>
                  <p>When you successfully join CryptoConquest DuckDsn Partners, you will automatically receive an amazing 30% to 50% Revenue Share based on the number of New Depositing Customers you send our way.</p>
                </div>
                <div className="btn-area">
                  <a href="javascript:void(0)" className="cmn-btn reg" data-bs-toggle="modal" data-bs-target="#loginMod">
                    Join Now
                    <i className="icon-d-right-arrow-2"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ReferSection;
