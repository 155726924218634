import React from 'react';
import facebookIcon from '../assets/images/icon/facebook.png';
import twitterIcon from '../assets/images/icon/twitter.png';
import instagramIcon from '../assets/images/icon/instagram.png';
import rightArrowIcon from '../assets/images/icon/right-arrow-2.png';

function SocialMedia() {
  return (
    <div>
      <section className="social-media">
        <div className="overlay pt-120 pb-120">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-lg-12">
                <div className="section-text text-center">
                  <h5 className="sub-title">Social Media</h5>
                  <h2 className="title">Follow us on</h2>
                </div>
              </div>
            </div>
            <div className="row cus-mar">
              <div className="col-xl-4 col-md-6">
                <div className="single-box">
                  <div className="icon-area">
                    <img src={facebookIcon} alt="image" />
                  </div>
                  <h4>Facebook</h4>
                  <p>Lorem ipsum dolor sit amet, consect adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolores. </p>
                  <a href="https://www.facebook.com/">
                    Follow US
                    <div className="icon-area">
                      <img src={rightArrowIcon} alt="icon" />
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-xl-4 col-md-6">
                <div className="single-box">
                  <div className="icon-area">
                    <img src={twitterIcon} alt="image" />
                  </div>
                  <h4>Twitter</h4>
                  <p>Lorem ipsum dolor sit amet, consect adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolores. </p>
                  <a href="https://www.twitter.com/">
                    Follow US
                    <div className="icon-area">
                      <img src={rightArrowIcon} alt="icon" />
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-xl-4 col-md-6">
                <div className="single-box">
                  <div className="icon-area">
                    <img src={instagramIcon} alt="image" />
                  </div>
                  <h4>Instagram</h4>
                  <p>Lorem ipsum dolor sit amet, consect adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolores. </p>
                  <a href="https://www.instagram.com/">
                    Follow US
                    <div className="icon-area">
                      <img src={rightArrowIcon} alt="icon" />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SocialMedia;
