import React from "react";
import userImage1 from "../assets/images/table-image-1.png";
import userImage2 from "../assets/images/table-image-2.png";
import userImage3 from "../assets/images/table-image-3.png";
import userImage4 from "../assets/images/table-image-4.png";
import userImage5 from "../assets/images/table-image-5.png";
import userImage6 from "../assets/images/table-image-6.png";
import userImage7 from "../assets/images/table-image-7.png";
import userImage8 from "../assets/images/table-image-8.png";
import userImage9 from "../assets/images/table-image-9.png";

function LatestWinner() {
  return (
    <div>
      <section className="latest-bets latest-winners">
        <div className="overlay pb-120">
          <div className="container wow fadeInUp">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="section-header text-center">
                  <h5 className="sub-title">Latest Winners</h5>
                  <h2 className="title">Latest Bets</h2>
                  <p>
                    More and more winners are added every time! To locate the
                    most recent winner's information
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">User</th>
                        <th scope="col">Date</th>
                        <th scope="col">Purchased</th>
                        <th scope="col">Winners</th>
                        <th scope="col">My Tickets</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">
                          <div className="icon-box">
                            <img src={userImage1} alt="Tom Bass" />
                            <span>Tom Bass</span>
                          </div>
                        </th>
                        <td>05/16/22</td>
                        <td>14,979</td>
                        <td className="game">8,022</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <div className="icon-box">
                            <img src={userImage2} alt="Karl Day" />
                            <span>Karl Day</span>
                          </div>
                        </th>
                        <td>05/25/22</td>
                        <td>13,854</td>
                        <td className="game">7,521</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <div className="icon-box">
                            <img src={userImage3} alt="Jon Arnold" />
                            <span>Jon Arnold</span>
                          </div>
                        </th>
                        <td>05/01/22</td>
                        <td>14,979</td>
                        <td className="game">8,569</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <div className="icon-box">
                            <img src={userImage4} alt="Kim Clark" />
                            <span>Kim Clark</span>
                          </div>
                        </th>
                        <td>05/23/22</td>
                        <td>21,979</td>
                        <td className="game">8,574</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <div className="icon-box">
                            <img src={userImage5} alt="Sergio Roy" />
                            <span>Sergio Roy</span>
                          </div>
                        </th>
                        <td>05/14/22</td>
                        <td>14,979</td>
                        <td className="game">8,523</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <div className="icon-box">
                            <img src={userImage6} alt="Wm Dixon" />
                            <span>Wm Dixon</span>
                          </div>
                        </th>
                        <td>05/05/22</td>
                        <td>15,979</td>
                        <td className="game">8,457</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <div className="icon-box">
                            <img src={userImage7} alt="Leo Berry" />
                            <span>Leo Berry</span>
                          </div>
                        </th>
                        <td>05/09/22</td>
                        <td>11,979</td>
                        <td className="game">8,358</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <div className="icon-box">
                            <img src={userImage8} alt="Ross Ryan" />
                            <span>Ross Ryan</span>
                          </div>
                        </th>
                        <td>05/14/22</td>
                        <td>12,979</td>
                        <td className="game">4,133</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <div className="icon-box">
                            <img src={userImage9} alt="Alton Ray" />
                            <span>Alton Ray</span>
                          </div>
                        </th>
                        <td>05/21/22</td>
                        <td>21,979</td>
                        <td className="game">12,245</td>
                        <td>0</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default LatestWinner;
