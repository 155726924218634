import React from 'react'
import { Link } from 'react-router-dom'

function WeeklyLottery() {
  return (
    <div>
       <section class="weekly-lottery">
        <div class="overlay pb-120">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-10">
                        <div class="lottery-content">
                            <div class="section-text text-center">
                                <h3 class="title">Weekly Lottery</h3>
                                <h5 class="sub-title">Game #379</h5>
                            </div>
                            <div class="prize-pool d-flex align-items-center justify-content-between">
                                <div class="left-area">
                                    <h5 class="sub-title xlr">Prize Pool</h5>
                                    <h4>19.41146725 BTC</h4>
                                    <p>143577 Tickets in Game</p>
                                </div>
                                <div class="right-area text-center">
                                    <h6 class="sub-title">Lottery Draw Starts In:</h6>
                                    <div class="countdown d-flex align-items-center">
                                        <h5>
                                            <span class="days">00</span><span class="ref">D:</span>
                                        </h5>
                                        <h5>
                                            <span class="hours">00</span><span class="ref">H:</span>
                                        </h5>
                                        <h5>
                                            <span class="minutes">00</span><span class="ref">M:</span>
                                        </h5>
                                        <h5>
                                            <span class="seconds">00</span><span class="ref">S</span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div class="bottom-area text-center">
                                <p>While you are still thinking, <span>6028 tickets</span> have already been so</p>
                                <Link to="/connect" class="cmn-btn">
                                    buy  Ticket for just $0.1
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  )
}

export default WeeklyLottery
