import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import BannerLottery from "../component/BannerLottery";
import WeeklyLottery from "../component/WeeklyLottery";
import LatestWinner from "../component/LatestWinner";
import Faq from "../component/Faq";
import LogReg from "../component/LogReg";

function Lottery() {
  return (
    <div>
      <Header />
      <BannerLottery />
      <LogReg />
      <WeeklyLottery />
      <LatestWinner />
      <Faq />
      <Footer />
    </div>
  );
}

export default Lottery;
